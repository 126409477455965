<template>
  <div class="pt-6">
      <ProjectCover :background="project.background" :logo="project.logo">{{
        project.description
      }}</ProjectCover>
      <div class="flex justify-center items-center mb-24">
        <img
          :src="project.screenshot.projectScreenshot"
          alt="Tagup project image"
          class="project-image"
        />
      </div>
      <section class="section min-h-50 bg-grey-lighter">
        <div class="section-content">
          <div class="flex justify-center md:justify-between flex-wrap">
            <div class="flex relative">
              <h2 class="text-left mb-6 h1">Serviced Provided</h2>
              <h2 class="background-heading h1 text-left">SERVICES</h2>
            </div>
            <div class="flex">
              <div class="flex flex-col">
                <span class="font-semibold text-sm">Efforts involved</span>
                <span class="font-semibold text-primary">{{
                  project.people
                }}</span>
              </div>
              <div class="services-vertical-line"></div>
              <div class="flex flex-col">
                <span class="font-semibold text-sm">Development time</span>
                <span class="font-semibold text-primary">{{ project.time }}</span>
              </div>
            </div>
          </div>
          <div
            class="flex flex-wrap justify-center sm:justify-start mt-8 md:mt-16"
          >
            <CircleBox
              v-for="(item, index) in project.services"
              :no="(index + 1).toString()"
              :key="index"
              >{{ item }}</CircleBox
            >
          </div>
        </div>
      </section>
      <section class="section min-h-50 bg-grey-lighter">
        <div class="section-content">
          <Heading
            background="Techno"
            >Technologies</Heading
          >
          <div class="grid-layout mt-8 md:mt-16">
            <Technology
              v-for="(item, index) in project.technologies"
              :image="item.image"
              :techName="item.name"
              :key="index"
              >{{item.description}}</Technology>
          </div>
        </div>
      </section>
      <section class="section min-h-50 bg-grey-lighter">
        <div class="section-content">
          <Heading
            background="Challenge"
            left
            >Challenge</Heading
          >
          <div
            class="flex flex-col items-center md:flex-row justify-between mt-8 md:mt-16"
          >
            <div class="flex flex-col">
              <Step v-for="(item, index) in project.challenge" :no="(index + 1).toString()" :key="index">
                {{item}}
              </Step>
            </div>
            <div>
              <img
                :src="project.screenshot.challengeScreenshot"
                alt="Project image"
                class="challenge-project__icon"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="section min-h-50 bg-grey-lighter">
        <div class="section-content">
          <Heading
            background="Results"
            >The Results</Heading
          >
          <div class="flex flex-col md:flex-row justify-between mt-16">
            <div class="my-8">
              <img
                :src="project.screenshot.resultScreenshot"
                alt="Project image"
                class="result-project__icon"
              />
            </div>
            <div class="flex flex-col md:ml-10">
              <div class="results-wrapper">
                <div>
                  {{project.result}}
                </div>
              </div>
              <Step v-for="(item, index) in project.results" :key="index">
                {{item}}
              </Step>
            </div>
          </div>
        </div>
      </section>
    <Estimate></Estimate>
  </div>
</template>

<script>
import Estimate from "@/views/subComponents/Estimate.vue";
import ProjectCover from "@/views/subComponents/ProjectCover.vue";
import { CircleBox, Technology, Heading, Step } from "@/components";
import projects from "@/projects.json"

export default {
  components: {
    Estimate,
    ProjectCover,
    CircleBox,
    Technology,
    Heading,
    Step
  },
  data() {
    return {
      project: {},
    };
  },
  mounted() {
    this.project = projects.find(x=> x.name === this.$route.params.project)
  }
};
</script>

<style lang="css" scoped></style>
