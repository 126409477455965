<template>
  <section
    id="project-details"
    class="min-h-70 flex flex-col justify-center items-center section bg-secondary relative"
  >
    <div
      class="background-container opacity-70"
      :style='`background-image: url(${background});`'
    ></div>
    <div class="z-10 flex items-center flex-col -mt-16">
      <img
        :src="'../img/projects/'+logo"
        alt="logo"
        class="project-logo pb-8"
      />
      <div
        class="w-full max-w-sm text-center text-white text-lg project-description"
      >
      <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props:{
    background:String,
    logo:String
  }
}
</script>

<style lang="css" scoped>
</style>
